'use client'
import { RootState } from '@/app/globalRedux/store'
import useNavItems from '@/app/hooks/useNavItems'
import { useTranslation } from '@/app/i18n/client'
import Image from 'next/image'
import Link from 'next/link'
import { useParams, usePathname } from 'next/navigation'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
const items = [
  'buyersAlert',
  'salesOffers',
  'news',
  'register',
  'events',
  'solutions',
  'aboutUs',
  'advertise',
]
const routeMap: Record<string, string> = {
  buyers_alert: 'buyersAlert',
  sales_offers: 'salesOffers',
  news: 'news',
  events: 'events',
  solutions: 'solutions',
  'about-us': 'aboutUs',
  advertise_page: 'advertise',
}
const findKeyByValue = (
  map: Record<string, string>,
  value: string
): string | null => {
  return map[value] || null
}
const NavItemsSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const pathname = usePathname()
  const params = useParams()
  const navItems = useNavItems()
  const userName = useSelector((state: RootState) => state.user.userName)
  const { t } = useTranslation()

  useEffect(() => {
    // Scroll to the initial active tab when component mounts
    const activeNav = pathname.split('/')[2]
    const indexVal = findKeyByValue(routeMap, activeNav)
    const index = items.indexOf(indexVal ?? '')
    const activeElement = document.getElementById(items[index])
    activeElement?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center',
    })
  }, [pathname])

  const scrollName = (id: string) => {
    const element = document.getElementById(id)
    element?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center',
    })
  }

  const handleScroll = (direction: 'prev' | 'next') => {
    if (direction === 'prev' && currentIndex > 0) {
      const newIndex = currentIndex - 1
      setCurrentIndex(newIndex)
      scrollName(items[newIndex])
    } else if (direction === 'next' && currentIndex < items.length - 1) {
      const newIndex = currentIndex + 1
      setCurrentIndex(newIndex)
      scrollName(items[newIndex])
    }
  }
  return (
    <div className="relative w-full flex py-2 mobile:py-0 justify-center items-center bg-[#eaa401d2] mobile:bg-transparent">
      <div className="w-[1280px] mobile-width relative">
        <div className="flex w-full mobile-width justify-center mobile:justify-start nav-scroll-item mobile:overflow-scroll mobile:ml-0">
          <ul
            className={`flex mobile:justify-evenly justify-center cursor-pointer text-base items-center h-[39.6px] mobile:relative px-10  mobile:px-10 mobile:gap-[0.8rem] ${pathname.includes('/en') ? 'gap-8' : 'gap-4'}`}
          >
            {navItems().map(
              ({ href, text, onClick, id, condition, pathIndentifier }) => {
                const isActive = pathIndentifier
                  ? pathIndentifier.includes(pathname.split('/')[2])
                  : false

                if (condition && !condition(userName)) return null
                return (
                  <li
                    key={id}
                    className={`${isActive || (condition && ['en', 'fr', 'it', 'es'].includes(pathname.split('/').pop() ?? 'en')) ? 'navActive font-ns_bold' : ''} top-nav-bar mobile:shadow-[0_0_6px_0_rgba(0,0,0,0.07)] mobile:px-3 mobile:py-1.5 mobile:border-b-2 mobile:border-orange`}
                    id={id}
                    onClick={onClick}
                  >
                    <Link
                      locale={false}
                      href={`/${params?.lng}${href}`}
                      prefetch={true}
                      replace
                    >
                      {t(text)}
                    </Link>
                  </li>
                )
              }
            )}
          </ul>
        </div>
        <div className="mobile:relative mobile-width mobile:flex justify-between hidden">
          <Image
            loading="lazy"
            width={30}
            height={30}
            src="/assets/Icons/scroll-left.svg"
            alt="scroll-left"
            className="hidden mobile:block mobile:w-[40px] mobile:h-[auto] mobile:absolute mobile:left-[-10px] mobile:top-[40px] bg-white"
            id="prevBtn"
            onClick={() => handleScroll('prev')}
          />
          <Image
            loading="lazy"
            width={30}
            height={30}
            src="/assets/Icons/scroll-right.svg"
            alt="scroll-right"
            className="hidden mobile:block mobile:w-[40px] mobile:h-[auto] mobile:absolute mobile:right-[-5px] mobile:top-[40px] bg-white"
            id="nextBtn"
            onClick={() => handleScroll('next')}
          />
        </div>
      </div>
    </div>
  )
}

export default NavItemsSection
