import { CompanyResponse } from '@/app/types/companySearchType'
import { NewsSearchResponse } from '@/app/types/newsSearchType'
import {
  formatCompanyFilterData,
  formatEventFilterData,
  formatNewsPayload,
  formatProductFilterData,
  formatRetailCompanyFilterData,
  formatRetailNewsPayload,
} from '@/app/utils/formatSearchData'
import { CompanyFilterState } from '../filter/companyFilter'
import { EventFilterState } from '../filter/eventFilter'
import { NewsFilterState } from '../filter/newsFilter'
import { ProductState } from '../filter/productFilter'
import { RetailCompanyFilterType } from '../filter/retail/retailCompanyFilter'
import { RetailNewsFilterType } from '../filter/retail/retailnewsFilter'
import { CommonFilterType } from './../filter/commonFilter'
import { apiSlice } from './apiSlice'

export const searchSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSearch: builder.query({
      query: (searchProps: {
        searchTerm: string
        lang: string | string[]
      }) => ({
        url: `/search/all?search_term=${searchProps.searchTerm}`,
        method: 'GET',
      }),
    }),
    getCompanySearch: builder.query<
      CompanyResponse,
      {
        companyFilter: CompanyFilterState
        commonFilter: CommonFilterType
        productFilter: ProductState
        lang: string
        pageNo: number
        search_on: string
      }
    >({
      query: ({
        companyFilter,
        commonFilter,
        productFilter,
        pageNo,
        lang,
        search_on,
      }) => ({
        url: `/search/companies?page=${pageNo}`,
        method: 'POST',
        body: formatCompanyFilterData(
          companyFilter,
          commonFilter,
          productFilter,
          lang,
          search_on
        ),
      }),
    }),
    getProductSearch: builder.query({
      query: ({
        productsFilter,
        commonFilter,
        pageNo,
      }: {
        productsFilter: ProductState
        commonFilter: CommonFilterType
        pageNo: number
      }) => ({
        url: `/search/products?page=${pageNo}`,
        method: 'POST',
        body: formatProductFilterData(productsFilter, commonFilter),
      }),
    }),
    getBuyerAlerts: builder.query({
      query: ({
        productsFilter,
        commonFilter,
        pageNo,
      }: {
        productsFilter: ProductState
        commonFilter: CommonFilterType
        pageNo: number
      }) => ({
        url: `/search/buyer_alerts?page=${pageNo}`,
        method: 'POST',
        body: formatProductFilterData(productsFilter, commonFilter),
      }),
    }),
    getSalesOffer: builder.query({
      query: ({
        productsFilter,
        commonFilter,
        pageNo,
      }: {
        productsFilter: ProductState
        commonFilter: CommonFilterType
        pageNo: number
      }) => ({
        url: `/search/sales_offers?page=${pageNo}`,
        method: 'POST',
        body: formatProductFilterData(productsFilter, commonFilter),
      }),
    }),
    getNewsSearch: builder.query({
      query: ({
        newsFilter,
        commonFilter,
        pageNO,
      }: {
        newsFilter: NewsFilterState
        commonFilter: CommonFilterType
        pageNO: number
      }) => ({
        url: `/search/news?page=${pageNO}`,
        method: 'POST',
        body: formatNewsPayload(newsFilter, commonFilter),
      }),
      transformResponse: async (responseData) => {
        return responseData
      },
    }),
    getEventSearch: builder.query({
      query: ({ eventFilter }: { eventFilter: EventFilterState }) => ({
        url: `/search/events?page=${eventFilter.pageNo}`,
        method: 'POST',
        body: formatEventFilterData(eventFilter),
      }),
    }),

    getRetailCompanyFilter: builder.query<
      CompanyResponse,
      {
        retailCompanyFilter: RetailCompanyFilterType
        pageNo: number
      }
    >({
      query: ({ retailCompanyFilter, pageNo }) => ({
        url: `/search/companies?page=${pageNo}`,
        method: 'POST',
        body: formatRetailCompanyFilterData(retailCompanyFilter),
      }),
    }),

    getRetailNewsSearch: builder.query<
      NewsSearchResponse,
      {
        retailNewsFilter: RetailNewsFilterType
        pageNO: number
      }
    >({
      query: ({ retailNewsFilter, pageNO }) => ({
        url: `/search/news?page=${pageNO}`,
        method: 'POST',
        body: formatRetailNewsPayload(retailNewsFilter),
      }),
    }),
  }),
})

export const {
  useGetSearchQuery,
  useGetCompanySearchQuery,
  useGetProductSearchQuery,
  useGetNewsSearchQuery,
  useGetBuyerAlertsQuery,
  useGetSalesOfferQuery,
  useGetEventSearchQuery,
  useGetRetailCompanyFilterQuery,
  useGetRetailNewsSearchQuery,
} = searchSlice
