import store from '@/app/globalRedux/store'
import { monthNameToNumber } from '@/app/utils/numberToMonth'
import { CommonFilterType } from '../globalRedux/filter/commonFilter'
import { CompanyFilterState } from '../globalRedux/filter/companyFilter'
import { EventFilterState } from '../globalRedux/filter/eventFilter'
import { NewsFilterState } from '../globalRedux/filter/newsFilter'
import { ProductState } from '../globalRedux/filter/productFilter'
import { RetailCompanyFilterType } from '../globalRedux/filter/retail/retailCompanyFilter'
import { RetailNewsFilterType } from '../globalRedux/filter/retail/retailnewsFilter'
import { languageTransition } from './language'

export const companySortOptions: Record<string, string> = {
  'Latest Company': 'updated_at',
  'Company Name': 'company_name.normalized',
}
export const productSortOptions: Record<string, string> = {
  'Publish Date': 'publication_date',
  'Importer Country': 'country.keyword',
  'Product Name': 'product_name.keyword',
}
export const newsSortOptions: Record<string, string> = {
  'Publish Date': 'publication_date',
  Title: 'title.normalized',
}
export const eventSortOptions: Record<string, string> = {
  Upcoming: 'upcoming',
  Oldest: 'oldest',
}
export const map_sector: Record<string, string> = {
  'Fruit & Vegetable': 'AF Production',
  'Pre - Post Harvest': 'AF Pre & Post Harvest',
  'Transport / Logistics': 'AF Transports',
  'Retail Chains': 'AF Retail Chains',
}

export const formatNewsPayload = (
  newsFilter: NewsFilterState,
  commonFilter: CommonFilterType
) => {
  const filter: { field: string; value: string[] | number[] }[] = []
  filter.push({
    field: 'country',
    value: commonFilter?.companyLocation.map((item) => item?.name),
  })
  filter.push({ field: 'criteria', value: newsFilter.criterias })
  filter.push({ field: 'languages', value: [newsFilter?.language] })
  filter.push({
    field: 'region',
    value: commonFilter?.companyLocationContinents?.map((item) => item.name),
  })
  const sort = [
    {
      field: newsSortOptions[newsFilter?.selectedNewsSortOption],
      value: { order: newsFilter?.order },
    },
  ]

  return {
    match: newsFilter.searchTerm,
    filter: filter,
    sort: sort,
    facet: ['activites_english', 'region'],
  }
}

export const formatCompanyFilterData = (
  companyFilterData: CompanyFilterState,
  commonFilter: CommonFilterType,
  productFilter: ProductState,
  lang: string,
  search_on: string
) => {
  const search_initial_load = store.getState().constantData.search_initial_load
  const filter: { field: string; value: string[] | number[] | string }[] = []
  const language = languageTransition
    ?.find((item) => item.path === lang)
    ?.language?.toLowerCase()
  if (companyFilterData.sector == 'Fruit & Vegetable') {
    filter.push({ field: 'sector', value: ['AF Production'] })
    filter.push({
      field: 'activites_english',
      value: companyFilterData.fvSelectedActivity.map((item) => item),
    })
    if (companyFilterData.cultivationType?.length > 0) {
      if (
        productFilter?.productFilterFvSelectedProducts?.length > 0 &&
        productFilter?.productFilterFvSelectedProducts?.length > 0 &&
        companyFilterData.cultivationType?.length === 1
      ) {
        filter.push({
          field: 'product_types',
          value: productFilter?.productFilterFvSelectedProducts?.map(
            (item) => `${item.name}|${companyFilterData.cultivationType}`
          ),
        })
      } else if (companyFilterData.cultivationType?.length !== 2) {
        filter.push({
          field: 'product_types',
          value: companyFilterData.cultivationType,
        })
      }
    }
  } else if (companyFilterData.sector == 'Pre - Post Harvest') {
    filter.push({ field: 'sector', value: ['AF Pre & Post Harvest'] })
  } else if (companyFilterData.sector == 'Transport / Logistics') {
    filter.push({ field: 'sector', value: ['AF Transports'] })
  } else if (companyFilterData.sector == 'Retail Chains') {
    filter.push({ field: 'sector', value: ['AF Retail Chains'] })
    filter.push({
      field: 'activites_english',
      value: companyFilterData.fvSelectedActivity.map((item) => item),
    })
  }
  filter.push({
    field: `products_${language}`,
    value: [
      ...productFilter?.productFilterFvSelectedProducts?.map(
        (item) => item.name
      ),
      ...productFilter?.productFilterPpSelectedProducts?.map(
        (item) => item.name
      ),
      ...productFilter?.productFilterTlSelectedProducts?.map(
        (item) => item.name
      ),
    ],
  })

  filter.push({
    field: 'country_name',
    value: commonFilter?.companyLocation?.map((item) => item.name) ?? [],
  })
  filter.push({
    field: 'region',
    value: commonFilter?.companyLocationContinents?.map((item) => item.name),
  })

  const sort = [
    {
      field: companySortOptions[companyFilterData?.companySelectedSortOption],
      value: { order: companyFilterData?.order },
    },
  ]

  return {
    search_on:
      search_on === 'company_name' ? search_on : `products_${language}`,
    match: companyFilterData.searchTerm ?? '',
    filter: filter,
    sort: sort,
    initial_load: search_initial_load,
    facet:
      companyFilterData.sector == 'Retail Chains'
        ? ['activites_english', 'region']
        : ['activites_english', 'region', 'product_family'],
  }
}

export const formatEventFilterData = (eventFilter: EventFilterState) => {
  const filter: {
    field: string
    value: string[] | number[] | undefined | Date
  }[] = []

  filter.push({
    field: 'country',
    value: eventFilter?.eventLocation?.map((item) => item?.name),
  })

  filter.push({
    field: 'region',
    value: eventFilter?.eventLocationContinents?.map((item) => item?.name),
  })

  if (eventFilter?.month) {
    filter.push({
      field: 'event_months',
      value: [monthNameToNumber[eventFilter?.month]],
    })
  }
  const sort = [
    {
      field: 'start_date',
      value: { order: eventFilter?.order },
    },
  ]
  return {
    match: eventFilter?.searchTerm || '',
    filter: filter,
    sort: sort,
    order: eventFilter?.selectedEventSortOption?.toLowerCase() || 'upcoming',
    facet: [],
  }
}

export const formatProductFilterData = (
  productsFilter: ProductState,
  commonFilter?: CommonFilterType
) => {
  const filter: { field: string; value: string[] | number[] | string }[] = []
  if (productsFilter.sector == 'Fruit & Vegetable') {
    filter.push({ field: 'sector', value: ['AF Production'] })
    filter.push({
      field: 'activities',
      value: productsFilter.productFruitVegetableActivity,
    })
    filter.push({
      field: 'cultivation',
      value: productsFilter.cultivationType,
    })
    filter.push({
      field: 'product_name',
      value: productsFilter.productFilterFvSelectedProducts.map(
        (item) => item.name
      ),
    })
    filter.push({
      field: 'product_origins',
      value: commonFilter?.productLocation?.map((item) => item?.name) ?? [],
    })
    filter.push({
      field: 'product_region',
      value:
        commonFilter?.productLocationContients?.map((item) => item?.name) ?? [],
    })
    if (productsFilter.productFVAvailability) {
      filter.push({
        field: 'availability',
        value: [monthNameToNumber[productsFilter.productFVAvailability]],
      })
    }
  } else if (productsFilter.sector == 'Pre - Post Harvest') {
    filter.push({ field: 'sector', value: ['AF Pre & Post Harvest'] })
    filter.push({
      field: 'product_name',
      value: productsFilter.productFilterPpSelectedProducts.map(
        (item) => item.name
      ),
    })
  } else if (productsFilter.sector == 'Transport / Logistics') {
    filter.push({ field: 'sector', value: ['AF Transports'] })
    filter.push({
      field: 'product_name',
      value: productsFilter.productFilterTlSelectedProducts.map(
        (item) => item.name
      ),
    })
    filter.push({
      field: 'product_name',
      value: productsFilter.productFilterTlSelectedProducts.map(
        (item) => item.name
      ),
    })
  }
  filter.push({
    field: 'company_location',
    value: commonFilter?.companyLocation?.map((item) => item?.name) ?? [],
  })
  filter.push({
    field: 'region',
    value:
      commonFilter?.companyLocationContinents?.map((item) => item.name) ?? [],
  })

  const sort = [
    {
      field: productSortOptions[productsFilter?.productSelectedSortOption],
      value: { order: productsFilter?.order },
    },
  ]

  return {
    filter: filter,
    sort: sort,
    match: productsFilter.searchTerm,
    facet: ['company_location', 'activities', 'product_family'],
  }
}

export const formatRetailCompanyFilterData = (
  retailCompanyFilter: RetailCompanyFilterType
) => {
  const filter: { field: string; value: string[] | number[] }[] = []
  filter.push({ field: 'sector', value: ['AF Retail Chains'] })
  filter.push({
    field: 'activites_english',
    value: retailCompanyFilter?.retailSelectedActivity.map((item) => item),
  })

  filter.push({
    field: 'country_name',
    value:
      retailCompanyFilter?.retailCompanyFilterCountry?.map(
        (item) => item.name
      ) ?? [],
  })
  filter.push({
    field: 'region',
    value: retailCompanyFilter?.retailCompanyFilterContienent?.map(
      (item) => item.name
    ),
  })
  const sort = [
    {
      field:
        companySortOptions[
          retailCompanyFilter?.retailCompanySelectedSortOption
        ],
      value: { order: retailCompanyFilter?.retailCompanyFilterOrder },
    },
  ]

  return {
    filter: filter,
    sort: sort,
    search_on: 'company_name',
    match: '',
    facet: ['activites_english'],
  }
}

export const formatRetailNewsPayload = (
  retailNewsFilter: RetailNewsFilterType
) => {
  const filter: { field: string; value: string[] | number[] }[] = []
  filter.push({
    field: 'country',
    value: retailNewsFilter?.retailNewsFilterCountry.map((item) => item?.name),
  })
  filter.push({
    field: 'criteria',
    value: ['Retail'],
  })

  filter.push({
    field: 'languages',
    value: [retailNewsFilter?.retailNewsFilterLanguages],
  })
  filter.push({
    field: 'region',
    value: retailNewsFilter?.retailNewsFilterContienent?.map(
      (item) => item.name
    ),
  })
  filter.push({
    field: 'sub_division',
    value: retailNewsFilter?.retailNewsFilterSubDivision,
  })
  const sort = [
    {
      field: newsSortOptions[retailNewsFilter?.retailNewsFilterSortOption],
      value: { order: retailNewsFilter?.retailNewsFilterOrder },
    },
  ]

  return {
    match: '',
    filter: filter,
    sort: sort,
  }
}
