'use client'
import { AppDispatch } from '@/app/globalRedux/store'
import { setLocaleLangauge } from '@/app/globalRedux/userStore/userStore'
import useScreenSize from '@/app/hooks/useScreenSize'
import Image from 'next/image'
import Link from 'next/link'
import { useParams, usePathname } from 'next/navigation'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
const lang = ['it', 'en', 'es', 'fr']

const LogoSection = () => {
  const screenSize = useScreenSize()
  const pathname = usePathname()
  const params = useParams()
  const dispatch: AppDispatch = useDispatch()

  useEffect(() => {
    dispatch(setLocaleLangauge(params?.lng ?? 'en'))
  }, [params?.lng])

  const navLeft = () => {
    return (
      screenSize?.width <= 768 &&
      (lang.includes(pathname.split('/')[pathname.split('/').length - 1]) ||
        pathname.split('/')[pathname.split('/').length - 1] == 'contact-us')
    )
  }
  return (
    <div
      className={`basis-2/12 mobile:basis-3/6 relative w-[198.4px] h-[79.6px] mobile:h-[78px] mobile:w-[150px] mobile:top-[-20px] ${navLeft() ? 'mobile:left-10' : ''}`}
    >
      <Link href={`/${params?.lng}`}>
        <Image
          loading="lazy"
          width={200}
          height={72}
          src={`/assets/logo/logo_${params?.lng ?? 'en'}.svg`}
          alt=""
        />
      </Link>
    </div>
  )
}

export default LogoSection
